import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

import eventBanner from "../../static/images/banners/event.png";

const ParticipatePage = () => {
	return (
		<Layout>
			<SEO title="Стань чемпионом!" />
			<section className="container participate-page">
				<div className="participate-page__wrapper">
					<div className="participate-page__img">
						<img src={eventBanner} />
					</div>
					<div className="participate-page__content">
						<h1>Запись на мероприятие</h1>
						<p>
							Записаться на выбранные мероприятия можно в мобильной версии приложения "Стань чемпионом!"
						</p>
						<p>Откройте ссылку на мобильном устройстве.</p>
					</div>
				</div>
			</section>
		</Layout>
	);
};

export default ParticipatePage;
